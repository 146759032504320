<template>
    <div class="wrapper">
        <div class="animated fadeIn">
            <BRow>
                <BCol cols="12" md="12">
                    <BCard header-tag="header" footer-tag="footer">
                        <div slot="header">
                            <BIconPeopleFill/> <strong> 회원관리 </strong>
                            <div class="card-header-actions">
                                <small class="text-muted">회원 정보를 관리합니다.</small>
                            </div>
                        </div>

                        <BRow class="mb-2">
                            <BCol sm="3">
                                <BInputGroup size="sm" prepend="등록년월">
                                    <BFormInput v-model="yearMonth"
                                                size = "sm"
                                                v-on:keyup.enter="getMemberList"/>
                                </BInputGroup>
                            </BCol>
                            <BCol sm="4">
                                <BInputGroup>
                                    <template v-slot:prepend>
                                        <BFormSelect size="sm"
                                                     v-model="searchField"
                                                     :options="searchOpts"/>
                                    </template>
                                    <BFormInput size="sm"
                                                v-model="searchWord"
                                                type="text"
                                                v-on:keyup.enter="getMemberList"/>
                                </BInputGroup>
                            </BCol>
                            <BCol sm="3" class="text-right">
                              <BInputGroup size="sm">
                                <BFormSelect size="sm" v-model="perPage" :options="[20,50,100, 200]"/>
                                <BInputGroupAppend>
                                  <BButtonGroup>
                                    <BButton size="sm" variant="primary" @click="getMemberList"><BIconArrowRepeat/></BButton>
                                    <BButton size="sm" variant="info" class="ml-1" @click="exportExcel"><BIconFileSpreadsheetFill/> Excel Download</BButton>
                                  </BButtonGroup>
                                </BInputGroupAppend>
                              </BInputGroup>
                            </BCol>
                        </BRow>

                        <vue-excel-editor
                                v-model="memberItems"
                                ref="memberGrid"
                                width="100%"
                                class="mb-1"
                                :page="perPage"
                                :readonly-style="{backgroundColor:'#EFE'}"
                                @select="rowSelected"
                                :localized-label="$localizedLabel"
                                filter-row>
                            <vue-excel-column field="mb_no" label="회원번호" type="number" width="70px"  key-field readonly/>
                            <vue-excel-column field="mb_id" label="아이디" width="170px"/>
                            <vue-excel-column field="mb_name" label="이름" width="100px"/>
                            <vue-excel-column field="mb_email" label="email" width="170px"/>
                            <vue-excel-column field="mb_nick" label="별명" width="100px"/>
                            <vue-excel-column field="mb_level" label="등급" width="50px"/>
                            <vue-excel-column field="mb_tel" label="연락처" width="130px"/>
                            <vue-excel-column field="mb_hp" label="휴대폰" width="130px"/>
                            <vue-excel-column field="mb_addr1" label="주소" width="150px"/>
                            <vue-excel-column field="mb_datetime" label="등록일" type="datetime" width="150px"/>
                            <vue-excel-column field="mb_today_login" label="로그인일시" type="datetime" width="150px"/>
                        </vue-excel-editor>

                        <BRow>
                            <BCol>
                                <BButtonGroup>
                                    <BButton variant="danger" @click="deleteMember">
                                        <BIconTrashFill/> 회원 정보 삭제
                                    </BButton>
                                </BButtonGroup>
                            </BCol>

                            <BCol>
                                <BProgress height="2rem" :value="progCount" :max="totalCount" variant="warning" show-value/>
                            </BCol>
                        </BRow>

                    </BCard>

                </BCol>
            </BRow>
        </div>
    </div>
</template>

<script>
    //-------------------------------------------------------------------------------------------------
    // import moment from 'moment';
    import {
        alertModal,
        alertSuccess,
        alertError,
        apiCall,
        apiMultiPart,
        cloneVar,
        confirmModal,
        getNotice, alertWarn, alertConfirm, alertSync
    } from '../../../common/utils';
    import qs from 'querystring';
    import moment from "moment";


    const _member = {
        mb_no             : null,
        mb_id             : null,
        mb_sn             : null,
        mb_password       : null,
        mb_name           : null,
        mb_nick           : null,
        mb_nick_date      : null,
        mb_email          : null,
        mb_homepage       : null,
        mb_level          : null,
        mb_sex            : null,
        mb_birth          : null,
        mb_tel            : null,
        mb_hp             : null,
        mb_certify        : null,
        mb_adult          : null,
        mb_dupinfo        : null,
        mb_zip1           : null,
        mb_zip2           : null,
        mb_addr1          : null,
        mb_addr2          : null,
        mb_addr3          : null,
        mb_addr_jibeon    : null,
        mb_signature      : null,
        mb_recommend      : null,
        mb_point          : null,
        mb_today_login    : null,
        mb_login_ip       : null,
        mb_datetime       : null,
        mb_ip             : null,
        mb_leave_date     : null,
        mb_intercept_date : null,
        mb_email_certify  : null,
        mb_email_certify2 : null,
        mb_memo           : null,
        mb_lost_certify   : null,
        mb_mailling       : null,
        mb_sms            : null,
        mb_open           : null,
        mb_open_date      : null,
        mb_profile        : null,
        mb_memo_call      : null,
        mb_1              : null,
        mb_2              : null,
        mb_3              : null,
        mb_4              : null,
        mb_5              : null,
        mb_6              : null,
        mb_7              : null,
        mb_8              : null,
        mb_9              : null,
        mb_10             : null,
        as_msg            : null,
        as_photo          : null,
        as_partner        : null,
        as_marketer       : null,
        as_exp            : null,
        as_level          : null,
        as_like           : null,
        as_liked          : null,
        as_follow         : null,
        as_followed       : null,
        as_response       : null,
        as_memo           : null,
        as_coupon         : null,
        as_join           : null,
        as_date           : null,
    };

    const roles = {'ROLE_USER': '일반 관리자', 'ROLE_ADMIN':'수퍼 관리자' };

    //----------------------------------------------------------------------------------------------------
    export default {
        name: 'Member',
        data () {
            return {
                previewImage: null,
                imgFile: null,
                member: null,
                originPassword: '',
                newPassword: '',
                rePassword: '',
                isMemberSelected: false,
                isMemberFormShow: false,
                memberItems: [],
                perPage: 20,
                currPage: 1,

                userRoleOptions: [
                    {value: 'ROLE_USER', text:'사용자'},
                    {value: 'ROLE_ADMIN', text:'관리자'}
                ],
                isBusy: false,

                searchOpts: [
                    {value:'mb_id', text:'회원ID'},
                    {value:'mb_name', text:'이름/별명'},
                    {value:'mb_email', text:'email'},
                    {value:'mb_hp', text:'전화번호'},
                ],
                searchWord: null,
                searchField: 'mb_id',
                actionMode: 'post',
                isAdmin: ($user.role==='ROLE_ADMIN')?true:false,
                progCount: 0,
                totalCount: 0,
                yearMonth: moment().format("YYYY-MM"),
                yearMonthOpts: []
            }
        },
        created(){

            this.member = cloneVar( _member );
            this.getMemberList();
        },
        computed: {
            userRows(){
                return this.memberItems.length;
            },
            validationOriginPassword(){
                return (this.originPassword!=='' && this.originPassword.length > 5);
            },
            validationPassword(){
                return (this.newPassword.length > 5 && this.newPassword===this.rePassword);
            },
            validationRePassword(){
                return this.rePassword.length > 5 && this.newPassword===this.rePassword;
            }
        },

        methods: {
            async getMemberList(){
              let r = null;
              let param = {mb_datetime: this.yearMonth};
              if( this.searchWord ){
                  param[this.searchField] = this.searchWord;
              }

              try{
                  this.isBusy = true;
                  r = await apiCall('get', `/admin/api/member/web?${qs.stringify(param)}`);
                  this.memberItems = r.result
              }catch(err){
                  console.log( err );
              }finally{
                  this.isBusy = false;
              }
            },
            exportExcel(){
              const format = 'xlsx';
              const filename = 'web-member-info-'+moment().format('YYYY-MM-DD');
              this.$refs.memberGrid.exportTable(format, true, filename)
            },


            rowSelected(item){
                console.log("------------ userRowSelected ------------");
            },

            async deleteMember(){
                let params = this.$refs['memberGrid'].getSelectedRecords();
                this.progCount = 0;
                this.totalCount = params.length;
                if( !params.length ){
                    await alertWarn( this.$bvModal, {text:"선택한 레코드가 없습니다."});
                    return;
                }

                if( params.length > 1 ){
                    await alertWarn( this.$bvModal, {text:"삭제 레코드는 1개만 선택할 수 있습니다."});
                    return;
                }

                if( !(await alertConfirm(this.$bvModal, `${params.length}개의 회원정보를 삭제 합니다. 진행 하시겠습니까?`, '정보삭제 경고')) ){
                    return;
                }

                let failCnt = 0;
                for( let memb of params){
                    console.log( "delete member --------->", memb );
                    const r = await apiCall("DELETE", `/admin/api/member/web/${memb.mb_no}`, memb);
                    console.log("approvalOrder----result--->", r);
                    if( r.result ){
                        this.progCount++;
                        await this.getMemberList();
                    }else{
                        failCnt++;
                    }
                }
                await alertSync(this.$bvModal, {text:`삭제: ${this.progCount}건, 실패: ${failCnt}`});

            },

        }
    }
</script>
